import React, { useEffect, useState, Component } from 'react';
import {
  Text,
  View,
  FlatList,
  TouchableWithoutFeedback,
  Image,
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Ionicons } from '@expo/vector-icons';

import { TextInput, Button } from 'react-native-paper';

import * as Print from 'expo-print';

import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue, child, get, remove , update} from "firebase/database";

import moment from 'moment';

import 'moment/locale/fr';

import { ActionSheetProvider } from '@expo/react-native-action-sheet';

import { useActionSheet } from "@expo/react-native-action-sheet";

import AsyncStorage from '@react-native-async-storage/async-storage';


// Optionally import the services that you want to use
//import {...} from "firebase/auth";
//import {...} from "firebase/database";
//import {...} from "firebase/firestore";
//import {...} from "firebase/functions";
//import {...} from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDVa1lT3zvPsi_YgTlpfQKULD8srL0Ot24",
  authDomain: "fire3auth.firebaseapp.com",
  databaseURL: "https://fire3auth.firebaseio.com",
  projectId: "fire3auth",
  storageBucket: "fire3auth.appspot.com",
  messagingSenderId: "499083359247",
  appId: "1:499083359247:web:44346cdca1d30b2b851f7a"
};

let myApp = initializeApp(firebaseConfig);


const database = getDatabase(myApp);


import { useFocusEffect } from '@react-navigation/native';

const html = `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
    <h1 style="font-size: 50px; font-family: Helvetica Neue; font-weight: normal;">
      Hello Expo!
    </h1>
    <img
      src="https://d30j33t1r58ioz.cloudfront.net/static/guides/sdk.png"
      style="width: 90vw;" />
  </body>
</html>
`;

function HomeScreen({ navigation }) {
  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Registre des armes',

      headerRight: () => (
        <View style={{ margin: 20, flexDirection: 'row' }}>
          <TouchableWithoutFeedback
            onPress={() => {
              printToFile();
            }}>
            <Ionicons
              name="print"
              size={24}
              color="#007aff"
              style={{ margin: 15 }}
            />
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback
            onPress={() => {
              navigation.navigate('Ajouter', {
                item: 'undefined',
              });
            }}>
            <Ionicons
              name="add-sharp"
              size={24}
              color="#007aff"
              style={{ margin: 15 }}
            />
          </TouchableWithoutFeedback>
        </View>
      ),
    });
  }, [navigation]);

    const [listeInfos, onChangeListeInfos] = React.useState([]);



    useEffect(() => {

    onValue(ref(database, '/armes/' ), (snapshot) => {

          // fetch your json here
    const json = snapshot.val()
    const list = [];
    for(const k in json) {
      list.push({
        id: k,
        ...json[k],
      })
    }
    console.log('ca donne quoi : ' + JSON.stringify(list));
      
      onChangeListeInfos(list)
  
    }, {
      onlyOnce: false
    });

}, []);

  const DATA = [
    {
      departement: 'GIRONDE',
      lieu: 'LESPARRE-MEDOC',
      date: '26/11/2022',
      nom: 'DUBOS',
      prenom: 'Rudy',
      naissance: '30/01/1996 à DAX',
      armeLongue: 'Fusil monocoup BERETTA numero 459289',
      armePoing: '',
      munitions: '5 Boites de 30 cartouche de 9mm',
      autres: '',
      pv: '1793',
      ref: '45',
    },
    {
      departement: 'GIRONDE',
      lieu: 'LESPARRE-MEDOC',
      date: '26/11/2022',
      nom: 'DUBOS',
      prenom: 'Rudy',
      naissance: '30/01/1996 à DAX',
      armeLongue: 'Fusil monocoup BERETTA numero 459289',
      armePoing: '',
      munitions: '5 Boites de 30 cartouche de 9mm',
      autres: '',
      pv: '1793',
      ref: '45',
    },
    {
      departement: 'GIRONDE',
      lieu: 'LESPARRE-MEDOC',
      date: '26/11/2022',
      nom: 'DUBOS',
      prenom: 'Rudy',
      naissance: '30/01/1996 à DAX',
      armeLongue: 'Fusil monocoup BERETTA numero 459289',
      armePoing: '',
      munitions: '5 Boites de 30 cartouche de 9mm',
      autres: '',
      pv: '1793',
      ref: '45',
    },
  ];

  const printToFile = async () => {
    Print.printToFileAsync({ html });
  };

  const html = `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
  </head>
  <body style="text-align: center;">
    <h1 style="font-size: 50px; font-family: Helvetica Neue; font-weight: normal;">
      Hello Expo!
    </h1>
  </body>
</html>
`;

  const { showActionSheetWithOptions } = useActionSheet();
  const openSheet = (item) => {
    const options = ["Supprimer", "Modifier", "Annuler"];


    const destructiveButtonIndex = 0; //the first element in 'options' will denote the Delete option
    const cancelButtonIndex = 2; //Element number 2 in the array will be the 'Cancel' button

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex: destructiveButtonIndex,
        message: "Vous pouvez, supprimer ou modifier votre arme.",
        title: "Que souhaitez vous faire ?",
        showSeparators: true,
      },
    (buttonIndex) => {

      if(buttonIndex === 0){

             
            remove(ref(database, 'armes/' + item.id));
        





      }else if(buttonIndex === 1){

        navigation.navigate('Ajouter', {
                item: item,
                modifier : 'true'
              });


      }else{


      }

    }
    );
  };

  const renderItem = ({ item }) => (
    <TouchableWithoutFeedback onPress={() => {


          openSheet(item)
              


    }}>

    <View
      style={{
        backgroundColor: 'white',
        flexDirection: 'row',
        borderTopWidth: 1,
      }}>
      <Text
        style={{
          flex: 1,
          borderRightWidth: 1,
          borderLeftWidth: 1,
          padding: 5,
        }}>
        {item.reff}/ARMES
      </Text>
      <Text style={{ flex: 1, borderRightWidth: 1, padding: 5 }}>
        {item.date}
      </Text>
      <Text style={{ flex: 1, borderRightWidth: 1, padding: 5 }}>
        01452/{item.pv}/2022
      </Text>
      <Text style={{ flex: 2, borderRightWidth: 1, padding: 5 }}>
        {item.armeLongue} + {item.armePoing} + {item.munitions} + {item.autres}
      </Text>
    </View>

    </TouchableWithoutFeedback>

  );

  return (
    <View style={{ flex: 1, backgroundColor: 'white', padding: 20 }}>
      <View style={{ backgroundColor: 'white', borderBottomWidth: 1 }}>
          <View
      style={{
        backgroundColor: 'white',
        flexDirection: 'row',
        borderTopWidth: 1,
      }}>
      <Text
        style={{
          flex: 1,
          borderRightWidth: 1,
          borderLeftWidth: 1,
          padding: 5,
          fontWeight:'bold',
          textAlign:'center'
        }}>
        NUMEROS
      </Text>
      <Text style={{ flex: 1, borderRightWidth: 1, padding: 5, fontWeight:'bold', textAlign:'center'}}>
        DATE
      </Text>
      <Text style={{ flex: 1, borderRightWidth: 1, padding: 5, fontWeight:'bold', textAlign:'center' }}>
        NUMERO PV
      </Text>
      <Text style={{ flex: 2, borderRightWidth: 1, padding: 5, fontWeight:'bold', textAlign:'center' }}>
        TYPES (ARMES / MUNITIONS)
      </Text>
    </View>
        <FlatList
          data={listeInfos.sort(function(a, b) {
            return a.reff - b.reff;
          }).reverse()}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>
    </View>
  );
}

function AddScreen({ route, navigation }) {
  const { item } = route.params;
  const { modifier } = route.params;


  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: 'Ajouter une arme',


    });
  }, [navigation, route]);

  const [departement, setDepartement] = React.useState('GIRONDE');
  const [lieu, setLieu] = React.useState('LESPARRE-MEDOC');
  const [date, setDate] = React.useState(item === 'undefined' ? moment(new Date()).format("Do/MM/YYYY") : item.date);

  const [nom, setNom] = React.useState(item === 'undefined' ? '' : item.nom);
  const [prenom, setPrenom] = React.useState(item === 'undefined' ? '' : item.prenom);
  const [naissance, setNaissance] = React.useState(item === 'undefined' ? '' : item.naissance);

  const [armeLongue, setArmeLongue] = React.useState(item === 'undefined' ? '' : item.armeLongue);
  const [armePoing, setArmePoing] = React.useState(item === 'undefined' ? '' : item.armePoing);
  const [munitions, setMunitions] = React.useState(item === 'undefined' ? '' : item.munitions);
  const [autres, setAutres] = React.useState(item === 'undefined' ? '' : item.autres);

  const [pv, setPv] = React.useState(item === 'undefined' ? '' : item.pv);
  const [reff, setReff] = React.useState(item === 'undefined' ? '' : item.reff);

  return (
    <View style={{ flex: 1, padding: 20, backgroundColor: 'white' }}>
      <TextInput
        label="Numero de Pv ( 01452/XXXXX/2022 )"
        value={pv}
        onChangeText={(text) => setPv(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />

      <TextInput
        label="Numero de reference ( XX/ARMES )"
        value={reff}
        onChangeText={(text) => setReff(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />

      <TextInput
        label="Nom"
        value={nom}
        onChangeText={(text) => setNom(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />

      <TextInput
        label="Prenom"
        value={prenom}
        onChangeText={(text) => setPrenom(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />
      <TextInput
        label="Date et lieu de naissance"
        value={naissance}
        onChangeText={(text) => setNaissance(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />

      <TextInput
        label="Arme longue"
        value={armeLongue}
        onChangeText={(text) => setArmeLongue(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />

      <TextInput
        label="Arme poing"
        value={armePoing}
        onChangeText={(text) => setArmePoing(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />

      <TextInput
        label="Munitions"
        value={munitions}
        onChangeText={(text) => setMunitions(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />

      <TextInput
        label="Autres"
        value={autres}
        onChangeText={(text) => setAutres(text)}
        mode="outlined"
        activeOutlineColor="#007aff"
      />

      {modifier ==='true' ? null :
      <Button
        mode="text"
        onPress={() => {


              set(ref(database, 'armes/' + new Date().getTime() ), {
                
      departement: departement,
      lieu: lieu,
      date: date,
      nom: nom,
      prenom: prenom,
      naissance: naissance,
      armeLongue: armeLongue,
      armePoing: armePoing,
      munitions: munitions,
      autres: autres,
      id:new Date().getTime(),
      pv: pv,
      reff: reff,


              });

              alert('Armes bien ajouté')

              navigation.goBack()






        }}
        color="#007aff">
        Ajouter
      </Button>}


      {modifier ==='true' ? null :
      <Button
        mode="text"
        onPress={() => {

                        set(ref(database, 'armes/' + new Date().getTime() ), {
                
      departement: departement,
      lieu: lieu,
      date: date,
      nom: nom,
      prenom: prenom,
      naissance: naissance,
      armeLongue: armeLongue,
      armePoing: armePoing,
      munitions: munitions,
      autres: autres,
      id:new Date().getTime(),
      pv: pv,
      reff: reff,


              });

              alert('Armes bien ajouté')

              setArmeLongue('')
              setArmePoing('')
              setMunitions('')
              setAutres('')
              setReff(Number(reff) + 1)



        }}
        color="#007aff">
        Ajouter et conserver le numero de PV
      </Button> }

      {modifier === 'true' ?
      <Button
        mode="text"
        onPress={() => {

                        update(ref(database, 'armes/' + item.id ), {
                
      departement: departement,
      lieu: lieu,
      date: date,
      nom: nom,
      prenom: prenom,
      naissance: naissance,
      armeLongue: armeLongue,
      armePoing: armePoing,
      munitions: munitions,
      autres: autres,
      id: item.id,
      pv: pv,
      reff: reff,


              });

              alert('Armes bien modifie')

              navigation.goBack()




        }}
        color="#007aff">
        Modifier
      </Button> : null}



      <Button
        mode="text"
        onPress={() => {


          navigation.navigate('Details', {
                item: 

    {
      departement: departement,
      lieu: lieu,
      date: date,
      nom: nom,
      prenom: prenom,
      naissance: naissance,
      armeLongue: armeLongue,
      armePoing: armePoing,
      munitions: munitions,
      autres: autres,
      pv: pv,
      reff: reff,
    }

                ,
              });


        }}
        color="#007aff">
        Imprimer
      </Button>

              
    </View>
  );
}

function DetailsScreen({ route, navigation }) {
  /* 2. Get the param */
  const { item } = route.params;

    useFocusEffect(
    
    React.useCallback(() => {
      setTimeout(() => {
        print();
        
      }, 1000);
    }, [])
  );

    const print = async () => {
    // On iOS/android prints the given html. On web prints the HTML from the current page.
    Print.printAsync({
      html,
    }).then(navigation.goBack());
    
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation, route]);
  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>


          <View style={{flexDirection:'row'}}>

            <View style={{ flex:1}}>

                  <Image
        style={{
          width: 300,
          height: 200,
        }}
        source={require('./image.png')}
      />

      </View>

      <View style={{ flex:1, paddingTop:25}}>
      <Text style={{ marginBottom:25, fontWeight:'bold'}}>Numero de Pv : 01452/{item.pv}/2022</Text>
      <Text style={{ marginBottom:25, fontWeight:'bold'}}>Numero de reference : {item.reff}/ARME</Text>

      </View>



      </View>



      <View style={{ backgroundColor: 'white', borderWidth: 1, margin: 25 }}>
        <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>
          Annexe n° 2 - Formulaire simplifié d'abandon d'arme
        </Text>
      </View>

      <View
        style={{
          borderWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: '#6794e0',
        }}>
        <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
          1. Site et date de l'abandon
        </Text>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Département
          </Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.departement}</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>Sites</Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.lieu}</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Date de dépôt
          </Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.date}</Text>
        </View>
      </View>



      <View
        style={{
          marginTop:25,
          borderWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: '#6794e0',
        }}>
        <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
          2. Déposant
        </Text>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Nom
          </Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.nom}</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>Prénom</Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.prenom}</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Date et lieu de naissance
          </Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.naissance}</Text>
        </View>
      </View>



      <View
        style={{
          marginTop:25,
          borderWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: '#6794e0',
        }}>
        <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
          3. Objet abandonné (préciser le n° de série de l'arme si visible ou tout élément d'identification)
        </Text>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Arme longue
          </Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.armeLongue}</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>Arme de poing</Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.armePoing}</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Munitions
          </Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.munitions}</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
            Autres
          </Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>{item.autres}</Text>
        </View>
      </View>


      <View style={{flexDirection:'row', margin:25}}>

            <View style={{ flex:1}}>

      </View>

      <View style={{ flex:1, borderWidth:1}}>
      <Text style={{ marginBottom:80, textAlign:'center', fontWeight:'bold'}}>Visa du recueillant</Text>

      </View>



      </View>


            <View
        style={{
          borderWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: '#6794e0',
        }}>
      </View>




      <View
        style={{
          marginTop:15,
          marginRight: 25,
          marginLeft: 25,
        }}>
        <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
      Partie réservée aux forces de l'ordre
        </Text>
      </View>



      <View
        style={{
          marginTop:15,
          borderWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: '#6794e0',
        }}>
        <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
4. Avis sur la réalisation d'examens balistiques        </Text>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>
Avis du recueillant          </Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
         
<Text style={{ fontWeight: 'bold', textAlign: 'left', marginLeft:25 }}>Opportun</Text>

<Text style={{ fontWeight: 'bold', textAlign: 'left', marginLeft:25, marginTop:15 }}>Non opportun</Text>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderLeftWidth: 1,
          marginRight: 25,
          marginLeft: 25,
          backgroundColor: 'white',
          flexDirection: 'row',
        }}>
        <View style={{ borderRightWidth: 1, flex: 1 }}>
          <Text style={{ fontWeight: 'bold', textAlign: 'left' }}>Avis de l'autorité hiérarchique</Text>

<Text style={{ fontWeight: 'bold', textAlign: 'left', marginLeft:25 }}>Directeur départemental de la sécurité publique</Text>

<Text style={{ fontWeight: 'bold', textAlign: 'left', marginLeft:25 }}>Commandant du groupement de gendarmerie départementale</Text>
        </View>

        <View style={{ borderRightWidth: 1, flex: 2 }}>
         
<Text style={{ fontWeight: 'bold', textAlign: 'left', marginLeft:25 }}>Opportun</Text>

<Text style={{ fontWeight: 'bold', textAlign: 'left', marginLeft:25, marginTop:15 }}>Non opportun</Text>
        </View>
      </View>




    </View>
  );
}




const Stack = createNativeStackNavigator();

export default function App() {





 



  return (


    <ActionSheetProvider>
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen name="Registre" component={HomeScreen} />
        <Stack.Screen name="Ajouter" component={AddScreen} />
        <Stack.Screen name="Details" component={DetailsScreen} />
      </Stack.Navigator>
    </NavigationContainer>
    </ActionSheetProvider>
  )



}
